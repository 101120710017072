import { AkamaiGeoCookie } from "nvs-constants";
import { PropsWithChildren } from "react";
import { GeoLocContext } from "./GeoLocProvider";

type AppGeoLocProvider = {
  geoLoc?: AkamaiGeoCookie;
};

export function AppGeoLocProvider(props: PropsWithChildren<AppGeoLocProvider>) {
  return (
    <GeoLocContext.Provider value={{ ...props.geoLoc }}>
      {props.children}
    </GeoLocContext.Provider>
  );
}
